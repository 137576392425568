import React from 'react';
import { CustomCellRenderKeyProps } from '@virtus/components/DxDataGrid/DxDataGrid';
import { CellData } from '../dxgrid-client-view.model';
import { ComplianceCellRender } from 'src/components/grids/cell-render/compliance-cell-render/compliance-cell-render';
import { ComplianceTestResult } from 'src/components/grids/cell-render/compliance-cell-render/compliance-cell-render-test-result';
import { ScenarioResultCell } from '../../hypo-scenario-grid/components/Cells';
import { DragCellRender } from './drag-cell-render/drag-cell-render';
import { MarketMapColumns } from 'src/models/marketmap/marketmap.model';
import { updateOnLoadCellValues } from 'src/utils/cell-calculation-field-rule';
import { PriceChangeCellRender } from '@virtus/components/DxDataGrid/cellRenders/price-change-cell-render/price-change-cell-render';
import { colors } from '@virtus/components/index';
import { PositionCellRender } from '@virtus/components/DxDataGrid/cellRenders/Position/PositionCellRender';
const complianceTestResultRender = {
  cellRender: (cellData: CellData) => {
    const value: string = (cellData?.value as string) ?? '';
    return <ComplianceTestResult renderValue={value} />;
  },
};
const complianceTestResultCellRender: CustomCellRenderKeyProps = {
  'Compliance Baseline Trigger': { ...complianceTestResultRender },
  'Baseline Value': { ...complianceTestResultRender },
  'Compliance Current Trigger': { ...complianceTestResultRender },
  'Current Value': { ...complianceTestResultRender },
};

const complianceCellRender = (isDarkTheme: boolean): CustomCellRenderKeyProps => {
  return {
    'Compliance Status': {
      cellRender: (cellData: CellData) => {
        const value: string = (cellData?.value as string) ?? '';

        return <ComplianceCellRender isDarkTheme={isDarkTheme} renderValue={value} />;
      },
    },
  };
};

const positionCellRender = (isDarkTheme: boolean): CustomCellRenderKeyProps => {
  return {
    Position: {
      cellRender: (cellData: CellData) => {
        const value: string = (cellData?.value as string) ?? '';
        return <PositionCellRender value={value} isDarkTheme={isDarkTheme} />;
      },
    },
  };
};

const selectionCellRenders = (dataSource: any): CustomCellRenderKeyProps => {
  return {
    issuer: {
      cellRender: (cellData: any) => (
        <div>
          <span style={{ color: colors.defaultBlue }}>{cellData.text}</span>
        </div>
      ),
    },
    drag: {
      cellRender: (cellData: any) => <DragCellRender cellData={cellData} />,
    },
    [MarketMapColumns.MarketMapLastPrice]: {
      cellRender: (cellData: CellData) => {
        const fieldKey = cellData?.column?.dataField.toString();
        const fieldInfo = dataSource.schema.filter((item: any) => item.display_name === fieldKey)[0];
        const fieldRulesValue = updateOnLoadCellValues(cellData, fieldInfo?.calculation_script_js, dataSource.schema);
        return (
          <PriceChangeCellRender
            cellData={cellData}
            value={fieldRulesValue ? fieldRulesValue : ''}
            format={fieldInfo?.format}
          />
        );
      },
    },
  };
};

const currentResultCellRender: CustomCellRenderKeyProps = {
  'Current Result': {
    cellRender: (cellData: CellData) => <ScenarioResultCell cellData={cellData} />,
  },
};

export default {
  complianceTestResultCellRender,
  complianceCellRender,
  selectionCellRenders,
  currentResultCellRender,
  positionCellRender,
};
