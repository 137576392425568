import { Checkbox, InputBase, MenuItem } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Warning } from '@material-ui/icons';
import BasicButton from '@virtus/components/Buttons/Basic/BasicButton';
import { colors } from '@virtus/components/theme';
import styled from 'styled-components';

export const StyledToggleButton = styled(BasicButton)<{ backgroundcolor?: string; width?: string }>`
  background-color: ${props => props.backgroundcolor || 'var(--grey)!important'};
  border-radius: 2px;
  margin-right: 1px;
  border: none;
  min-width: 0;
  &:hover {
    background-color: ${props => props.backgroundcolor || 'var(--grey)!important'};
  }
  &:disabled {
    background-color: ${props => props.backgroundcolor || 'var(--grey)!important'};
  }
  width: 46px;
  height: 28px;
  text-transform: capitalize;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 4px;
  align-items: center;
  width: 100%;
`;

const BaseInputStyles = `
  flex: 1;
  border-radius: 2px;
  border: none;
  color: var(--text);
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;

`;

export const Error = styled.div`
  width: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StyledWarningIcon = styled(Warning)`
  font-size: 14px;
`;

export const Input = styled(InputBase)<{ multiline?: boolean; error?: boolean }>`
  ${BaseInputStyles}
  height: ${props => (props.multiline ? '70px' : '28px')};
  align-items: ${props => (props.multiline ? 'flex-start' : 'center')};
  overflow: ${props => (props.multiline ? 'auto' : 'hidden')};
  line-height: 16px;
  border: ${props => (props.error ? '2px solid var(--red)' : 'none')};
  display: flex;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: var(--grey);
  ::placeholder {
    font-family: 'Nunito Sans', 'Roboto', 'Helvetica Neue', sans-serif !important;
    color: red !important;
  }
`;

export const DisabledField = styled.div`
  ${BaseInputStyles}
  background-color: transparent;
  color: var(--subtitle);
  cursor: not-allowed;
  height: 28px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
`;

export const StyledCheckbox = styled(Checkbox)`
  ${BaseInputStyles}
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  flex: 0;
  padding: 0;
`;

export const StyledLabel = styled.label<{ width?: string }>`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  font-size: 13px;
  margin: 0;
  padding: 0;
  width: ${props => props.width || '150px'};
  color: var(--inspector-label-grey);
`;

export const StyledInput = withStyles({
  disabled: {
    color: 'var(--text)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    overflow: 'hidden',
    lineHeight: '10px',
    backgroundColor: 'transparent !important',
  },
  input: {
    padding: '6px',
  },
})(Input as any);

export const MenuItemStyled = withStyles({
  root: {
    height: 36,
    width: 210,
    margin: 0,
    padding: 2,
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2.14,
    letterSpacing: -0.2,
    color: colors.text,
    backgroundColor: '#3c434b',
    '&:hover': {
      backgroundColor: '#474e56',
    },
  },
})(MenuItem);

export const MenuItemTextStyled = styled.div`
  display: flex;
  padding: 6px 0 6px 10px;
  width: 100%;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: -0.2px;
  color: ${colors.text};
`;

export const StyledRequiredStar = styled.span`
  color: var(--red);
`;

export const ModalButton = styled.button<{ editIsEnabled?: boolean }>`
  ${BaseInputStyles}
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: ${props => props.color || 'var(--text)'};
  background-color: ${props => (props.editIsEnabled ? 'var(--grey)' : 'transparent')};
`;
