import PaletteIcon from '@material-ui/icons/Palette';
import { DropdownMenuItem, DropdownMenuItemText } from '@virtus/components/DropdownMenu';
import ToolBar from '@virtus/components/tool-bar';
import { ToolBarProps } from '@virtus/components/tool-bar/tool-bar';
import React from 'react';
import { connect } from 'react-redux';
import { dispatchActions } from 'src/app/store';
import { RootState } from 'src/reducers';
import { selectComponents } from 'src/reducers/components';

export interface GlideToolbarProps extends Pick<ToolBarProps, 'extraIconButtons' | 'links' | 'onClickTab'> {
  pageToolBarProps?: any;
  views: { [viewKey: string]: any };
  activeViewKey: string;
  isInspectorCollapsed: boolean;
  isColumnManagerVisible: boolean;
  isSearchInspectorVisible: boolean;
  isLayoutManagerVisible: boolean;
}

interface ReduxProps {
  components?: any;
}

export enum ThemeNames {
  light = 'light',
  dark = 'dark',
}

export const GlideToolbar = ({
  views,
  activeViewKey,
  onClickTab,
  extraIconButtons,
  links,
  pageToolBarProps,
  components,
  isInspectorCollapsed,
  isColumnManagerVisible,
  isSearchInspectorVisible,
  isLayoutManagerVisible,
}: GlideToolbarProps & ReduxProps) => {
  const updateTheme = () =>
    dispatchActions.components.update('global', { isDarkTheme: !components.global.isDarkTheme });

  const items = [
    {
      text: `Change grid theme to ${components.global.isDarkTheme ? ThemeNames.light : ThemeNames.dark}`,
      icon: PaletteIcon,
      onClick: updateTheme,
    },
  ];

  const customParseViews = (views: any) =>
    views.map((view: any) => {
      const { id, name, loading = false } = view;
      return {
        id,
        name,
        loading,
      };
    });

  const moreButtonItems = () =>
    items.map((Item, index) => (
      <DropdownMenuItem key={index} onClick={Item.onClick}>
        <Item.icon />
        <DropdownMenuItemText>{Item.text}</DropdownMenuItemText>
      </DropdownMenuItem>
    ));

  const labels = Object.entries(views).map(([key, value]: any, index) => ({
    key,
    id: index,
    name: value.displayName,
  }));

  const onClickTabHandler = (id: number) => {
    const selectedTab = labels.find(label => label.id === id);
    onClickTab(selectedTab?.key);
  };

  const toolbarProps: ToolBarProps = {
    labels,
    active: labels.findIndex(label => label.key === activeViewKey.lastSplitValue()),
    links,
    onClickTab: onClickTabHandler,
    moreButtonItems,
    extraIconButtons,
    customParseViews,
    viewContainerStyles: { width: '65vw' },
    isInspectorCollapsed,
    isColumnManagerVisible,
    isSearchInspectorVisible,
    isLayoutManagerVisible,
  };

  return <ToolBar {...toolbarProps} {...pageToolBarProps} />;
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  components: selectComponents(state),
});

export default connect(mapStateToProps)(GlideToolbar);
