import DxDataGrid, { DxSummary } from '@virtus/components/DxDataGrid';
import { DxDataGridProps } from '@virtus/components/DxDataGrid/DxDataGrid';
import { columnManagementIcon } from '@virtus/components/DxDataGrid/icons/svgicons';
import { DxHeaderText } from '@virtus/components/DxDataGrid/model/DxDataGrid.model';
import { DataSourceType } from '@virtus/components/DxDataGrid/utils/mapSchema';
import { getSummaryGlide, GlideDataType, glideExcelExport } from '@virtus/components/DxDataGrid/utils/mapSchemaGlide';
import { GridActionColumn, RowAction } from 'src/components/grids/grid-action-column/grid-action-column';
import { Template } from 'devextreme-react/core/template';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import indicatorSrc from 'src/assets/glide.gif';
import { GlideDataContentProps } from 'src/components/forms/glide-data-content';
import { useGlideObjectManager } from 'src/components/glide-object-manager/hooks/use-glide-object-manager';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import GridTemplates, { getLivePriceToggleStatus } from 'src/components/grids/grids.template';
import { ColumnManagerInspectorProps } from 'src/components/inspectors/column-manager-inspector/column-manager-inspector';
import { Context } from 'src/contexts/grid-theme/grid-theme.context';
import { useMarketmap } from 'src/hooks/use-marketmap';
import {
  MarketMapColumns,
  MarketmapLivePriceDataType,
  marketmapRealTimeConfig,
  MarketmapRealTimeInfo,
} from 'src/models/marketmap/marketmap.model';
import { RootState } from 'src/reducers';
import { actions } from 'src/reducers/actions';
import { selectComponents } from 'src/reducers/components';
import { getActionsCollectionFromView, IAction } from 'src/utils/action-resolver';
import { onColumnGrouped, gridFilterChanges } from 'src/utils/common';
import { DEFAULT_TOOLBAR_ICONS_ORDER, MarketmapFields } from 'src/utils/constants';
import { CellData, SelectionChangeData } from './dxgrid-client-view.model';
import LayoutsTemplateRender, { LayoutsTemplateRenderProps } from './templates/Layouts/Layouts';
import SelectTemplateRender from 'src/components/Select/Select';
import { dispatchActions } from 'src/app/store';
import { actions as reduxActions } from 'src/reducers/actions';
import { store } from 'src/app/store';
import cellRenderers from './cell-renders/dxgrid-client-view.custom-cell-render';

const selectRow = (e: any, onClick: DxGridClientViewProps['onCellClick'], dataGridRef: React.RefObject<any>) => {
  const isNotAnActionButton = [...e.cellElement.classList][0] !== 'dx-command-edit';
  const isAValidCell = e.rowType === 'data';
  if (isAValidCell && isNotAnActionButton) {
    dataGridRef.current.instance.selectRowsByIndexes([e.row?.rowIndex]);
    if (onClick) {
      onClick(e);
    }
  }
};

export interface DxContextItem {
  text: string;
  icon?: string;
  onItemClick: () => void;
}

export interface DxContextEvent {
  component: Object;
  element: any;
  model: Object;
  target: string;
  targetElement: any;
  columnIndex: number;
  column: Object;
  rowIndex: number;
  row: any;
  items: Array<DxContextItem>;
}

export type ToolBarButtonsType = {
  add: any[];
  addBefore: any[];
  addAfter: any[];
  remove: any[];
};

export const addCloseMenuOption = (contextEvent: DxContextEvent) => {
  if (contextEvent.target === 'header') {
    const closeItem = {
      text: 'Close',
      onItemClick: () => {
        const element = document.getElementsByClassName('dx-submenu')[0] as HTMLElement;
        if (element) {
          element.style.visibility = 'hidden';
        }
      },
    };

    contextEvent.items.push(closeItem);
  }
};

export interface DxGridClientViewProps
  extends Omit<DxDataGridProps, 'dataSourceType'>,
    Omit<
      LayoutsTemplateRenderProps,
      'dataGridRef' | 'onClearLayout' | 'dataSource' | 'clientViewUri' | 'useDarkTheme'
    > {
  id: string;
  headerText?: DxHeaderText;
  actionsCollection?: any;
  dataGridRef: any;
  cartItems?: any[];
  onContextMenuOpen?: (e: any) => void;
  onContextItemClick?: () => void;
  onSettingsClick: (_dataGridRef?: any) => void;
  enableRowDragging?: boolean;
  enableLayouts?: boolean;
  onCellClick?: (cellData: CellData) => void;
  rowActions?: RowAction[];
  cellsWithDisabledClick?: string[];
  objectEditActions?: GlideDataContentProps['objectEditActions'];
  objectEditInspectors?: GlideDataContentProps['objectEditInspectors'];
  scrollingProps?: any;
  gridToolbarButtons?: ToolBarButtonsType;
  getGridTemplates?: (e?: GridTemplateProps) => void;
  clientViewConfiguration?: ClientViewConfigurationData;
  proposedOrdersVisible?: boolean;
  exportFileName?: string;
  onRowPrepared?: (cellData: CellData) => void;
  onRowClick?: (cellData: CellData) => void;
}

export interface GridTemplateProps {
  useDarkTheme?: boolean;
}

interface ReduxProps {
  components: any;
}

interface ReduxDispatch {
  resolveAction: (action: any, targetUri?: string) => void;
}

const getToolbarButtons = ({
  add,
  addBefore,
  addAfter,
  openColumnManagerClickHandler,
  onSearchClick,
  gridToolbarButtons,
  showSearchText,
}: {
  openColumnManagerClickHandler: ((e: any) => void) | null;
  add: any;
  addBefore: any[];
  addAfter: any[];
  onSearchClick: ((add: any) => void) | null;
  gridToolbarButtons: any;
  showSearchText?: boolean;
}) => {
  if (!showSearchText) {
    add.unshift({
      name: 'searchButton',
      widget: 'dxButton',
      cssClass: 'vertical-separator-line',
      options: {
        icon: 'search',
        onClick: () => {
          if (onSearchClick) {
            onSearchClick(add);
          }
        },
        hint: 'Search',
        elementAttr: { 'data-testid': 'search-btn-action-panel' },
      },
      location: 'after',
    });
  }

  if (openColumnManagerClickHandler) {
    addAfter.unshift({
      name: 'columnManagementButton',
      widget: 'dxButton',
      options: {
        icon: columnManagementIcon,
        onClick: openColumnManagerClickHandler,
        hint: 'Column Management',
        elementAttr: { 'data-testid': 'columnManager-btn' },
      },
      location: 'after',
    });
  }

  if (gridToolbarButtons) {
    add = [...add, ...gridToolbarButtons.add];
    addAfter = [...addAfter, ...gridToolbarButtons.addAfter];
    addBefore = [...addBefore, ...gridToolbarButtons.addBefore];
  }

  return {
    remove: ['columnChooserButton'],
    add,
    addAfter,
    addBefore,
  };
};

/***********Marketmap changes ******** */

const handleMarketmapResponse = (
  marketmapResData: { [key: number]: MarketmapLivePriceDataType },
  realTimeInfo: MarketmapRealTimeInfo,
  realTimeDataStore: ArrayStore | undefined,
) => {
  for (const key in marketmapResData) {
    const keyObj = marketmapResData[key];
    if (keyObj && keyObj.Symbol && !keyObj.Error) {
      const formattedRes = {
        id: key,
        'Last Price': keyObj[MarketmapFields.LAST_PRICE],
        'Net Change': keyObj[MarketmapFields.NET_CHANGE],
        'Perc Net Change': keyObj[MarketmapFields.PERC_NET_CHANGE],
      };
      /**
       * At present we need to update only last price
       * @todo in @future this can be updated later to handle multiple columns update
       */
      if (formattedRes['Last Price'] && formattedRes['Net Change']) {
        formattedRes['Last Price'] = formatVal(formattedRes['Last Price']);
        formattedRes['Net Change'] = formatVal(formattedRes['Net Change']);
        formattedRes['Perc Net Change'] = formatVal(formattedRes['Perc Net Change']);
        updateDataStore(realTimeDataStore, formattedRes, realTimeInfo.columnToUpdate);
      }
    }
  }
};

/**
 * Currently we are updating only last price
 * @todo in @future handle multiple columns update based on business req
 */
const updateDataStore = (realTimeDataStore: ArrayStore | undefined, formattedRes: any, columnToUpdate: string) => {
  realTimeDataStore?.push([
    {
      type: 'update',
      key: +formattedRes.id - 1,
      data: {
        [columnToUpdate]: formattedRes['Last Price'] + ' ' + formattedRes['Net Change'],
      },
    },
  ]);
};

// eslint-disable-next-line no-confusing-arrow
const formatVal = (val: any) =>
  val?.toString().indexOf('[') >= 0 ? Number(val.substring(1, val.length - 1)) : Number(val);

/***********Marketmap changes ******** */

export const DxGridClientView = ({
  id,
  onSelectionChanged,
  storageKey,
  headerText = {},
  dataGridRef,
  dataSource,
  onCellClick,
  resolveAction,
  actionsCollection,
  onRefresh,
  customCellRenderKeyProps,
  enableLayouts = true,
  enableMultipleSelection = false,
  enableRowDragging = false,
  cellsWithDisabledClick = [],
  components,
  objectEditInspectors,
  objectEditActions,
  loadPanelEnabled,
  onSettingsClick,
  scrollingProps,
  gridToolbarButtons,
  getGridTemplates,
  clientViewConfiguration,
  showPaging,
  glideSummaryOptions,
  exportFileName,
}: DxGridClientViewProps & ReduxProps & ReduxDispatch) => {
  const { useDarkTheme } = useContext(Context);
  const [showSearchText, setShowSearchText] = useState(false);
  const [actionCollectionActive, setActionCollectionActive] = useState(false);

  const clientViewUri = clientViewConfiguration?.uri || id;
  if (!clientViewUri && !dataGridRef?.current?.props?.id) return null;

  const [rowActions, setRowActions] = useState<RowAction[]>([]);
  /**
   * ************** Marketmap related functionality changes @start ******************
   */
  const {
    isLoggedInToMarketmap,
    marketMapToggleStatus,
    reqCountId,
    showMMAuthenticationForm,
  } = components.livePriceToggleState;

  const { livePrices, monitorLivePrices, stopMonitorLivePrices } = useMarketmap();
  const [realTimeDataSource, setRealTimeDataSource] = useState<DataSource | null>(null);
  const [showLoadPanelEnabled, setShowLoadPanelEnabled] = useState(loadPanelEnabled);
  const [showIndicatorSrc, setShowIndicatorSrc] = useState(indicatorSrc);
  const realTimeDataStore = useRef<ArrayStore | undefined>(undefined);
  const marketMapToggleFlag = getLivePriceToggleStatus(
    marketMapToggleStatus,
    clientViewConfiguration?.uri?.lastSplitValue() as string,
    isLoggedInToMarketmap,
  );

  const { toolbarButtonsFromConfig, toolbarTemplates, livePriceEnabled } = GridTemplates({
    dataSource,
    clientViewConfiguration,
  });

  const realTimeInfo: MarketmapRealTimeInfo | null = livePriceEnabled ? marketmapRealTimeConfig : null;

  useEffect(() => {
    if (clientViewConfiguration?.static_menu_actions) {
      const row_actions =
        clientViewConfiguration?.static_menu_actions?.map((action: any) => {
          return {
            text: action.data.display_name,
            onClick: (uri: string) =>
              store.dispatch({
                type: reduxActions.action.DISPATCH_ACTION,
                payload: { action, targetUri: uri },
              }),
          };
        }) || [];
      setRowActions(row_actions);
    }
  }, [clientViewConfiguration?.static_menu_actions]);

  /**
   * Needed to reset columngrouped and global order state as it is shared with multiple components
   */
  useEffect(() => {
    return () => {
      dispatchActions.components.update('global', {
        orders: { disabled: true, rowData: {} },
      });
      dispatchActions.components.updateView('gridLayout', clientViewUri, {
        hasChanges: false,
      });
      dispatchActions.components.updateView('columnGrouped', clientViewUri, {
        isGrouped: false,
        isExpanded: false,
      });
    };
  }, []);
  /**
   * Needed to hide loadpanel/indicator when live streaming is on
   * As it looks awkward if for every price update, loader will appear
   */
  useEffect(() => {
    if (realTimeInfo) {
      setShowLoadPanelEnabled(false);
      setShowIndicatorSrc(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Initialize/Re-create realTimeDataStore and realTimeDataSource
   * once dataSource is defined or gets updated
   */
  useEffect(() => {
    if (realTimeInfo && dataSource && dataSource.data.length > 0) {
      if (realTimeDataStore.current || reqCountId > 0) {
        /**
         * To avoid live streaming of data based on cached dataSource
         * As per current tab navigation we are making ajax request on every tab switch
         * So our realtimeData should be configured based on latest dataSource fetched from API call.
         */
        stopMonitorLivePrices();
        realTimeDataStore.current = undefined;
      }
      realTimeDataStore.current = new ArrayStore({
        key: realTimeInfo.id,
        data: [...dataSource.data],
      });
      const realTimeDataSource = new DataSource({
        store: realTimeDataStore.current,
        reshapeOnPush: true,
      });
      setRealTimeDataSource(realTimeDataSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, realTimeInfo]);

  /**
   * Register tickers with marketmap to monitor last price and other values
   */
  useEffect(() => {
    if (realTimeInfo && isLoggedInToMarketmap && realTimeDataSource && marketMapToggleFlag) {
      const tickerList = dataSource.data.map(item => item[realTimeInfo.columnToUse]);
      monitorLivePrices(tickerList, realTimeInfo.marketmapFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeInfo, realTimeDataSource, isLoggedInToMarketmap, marketMapToggleFlag]);

  /**
   * When field value changes update the grid by updating @var realTimeDataStore
   */
  useEffect(() => {
    if (realTimeInfo && livePrices) {
      handleMarketmapResponse(livePrices, realTimeInfo, realTimeDataStore.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [livePrices]); // dont forget to add livePrices

  const { objectCollectionColumns, GlideObjectManagerOverlay } = useGlideObjectManager({
    schema: dataSource?.schema,
    objectEditActions,
    objectEditInspectors,
    gridActions: {
      refresh: onRefresh,
    },
    id,
  });

  const handleOnSelectionChanged = (data: SelectionChangeData) => {
    if (data.selectedRowsData.length > 0) {
      setActionCollectionActive(true);
    }
    if (onSelectionChanged) {
      onSelectionChanged(data);
    }
  };

  if (
    enableRowDragging &&
    !dataSource?.schema.find((column: { accessor: string; display_name: string }) => column.display_name === 'drag')
  ) {
    dataSource.data = dataSource.data.map((row: any) => {
      return { ...row, drag: row };
    });
    dataSource.schema.push({
      display_name: 'drag',
      caption: '',
      data_type: 'Object',
      fixed: true,
      width: 50,
    });
  } else if (
    !enableRowDragging &&
    dataSource?.schema.find((column: { accessor: string; display_name: string }) => column.display_name === 'drag')
  ) {
    dataSource.schema = dataSource.schema.filter(
      (column: { accessor: string; display_name: string }) => column.display_name !== 'drag',
    );
    dataSource.data = dataSource.data.map((data: { [key: string]: string | number }) => {
      delete data.drag;
      return data;
    });
  }

  dataSource.schema = dataSource.schema
    ? dataSource.schema.map((column: any) => {
        if (column.display_name === 'Order Date') {
          column.sortOrder = 'desc';
          column.sortIndex = 0;
          column.allowSorting = false;
        }
        return column;
      })
    : [];

  const handleOnCellClick = (e: any) => {
    if (e.column?.fixed) return;
    if (cellsWithDisabledClick.includes(e?.cellElement?.firstChild?.id)) return;
    if (e?.column?.dataType === GlideDataType.ObjectCollection && e.event.target.tagName.toLowerCase() === 'span') {
      return;
    }
    if (!e?.data || e?.rowType === 'group') return;
    dispatchActions.components.update('actionForm', {
      target_uri: e.data._uri,
    });
    selectRow(e, onCellClick, dataGridRef);
    dispatchActions.components.update('global', {
      orders: { disabled: false, rowData: e?.data },
    });
  };

  const handleOnRowPrepared = (e: any) => {
    if (e?.rowType === 'header') return;
    if (e?.rowType === 'data') {
      if (e.data['Current Result'] === 'Passed') {
        e.rowElement.style.backgroundColor = useDarkTheme ? 'var(--dark-green)' : 'var(--light-green)';
      }
      if (e.data['Current Result'] === 'Failed') {
        e.rowElement.style.backgroundColor = useDarkTheme ? 'var(--errorDark)' : 'var(--light-red)';
      }
    }
  };

  const getDxGridColumns = useCallback(
    () =>
      (dataGridRef?.current?.instance?.state()?.columns as ColumnManagerInspectorProps['columns'])?.filter(
        column => column.dataField,
      ),
    [dataGridRef?.current],
  );

  /*
   * Open Column Manager Inspector passing the current DXGrid columns as props
   */
  const showColumnManager = useCallback(() => {
    const onSaveColumns = (updatedColumns: ColumnManagerInspectorProps['columns']) => {
      const state = {
        ...dataGridRef?.current?.instance?.state(),
        columns: updatedColumns,
      };
      dataGridRef?.current?.instance?.state(state);
      dispatchActions.components.updateView('gridLayout', clientViewUri, {
        hasChanges: true,
      });
      dispatchActions.components.updateView('columnManagerInspector', clientViewUri, { visible: false });
    };

    const columnManagerInspectorPayload = {
      visible: true,
      props: {
        columns: getDxGridColumns(),
        title: 'Column Manager',
        loading: false,
        onSave: onSaveColumns,
      },
    };
    dispatchActions.components.updateView('columnManagerInspector', clientViewUri, columnManagerInspectorPayload);
    dispatchActions.components.update('global', {
      currentClientViewUri: clientViewUri,
    });
  }, [dataGridRef, getDxGridColumns]);

  /*
   * Update Colum Manager Inspector columns when change the layout
   */
  const updateColumnManagerColumns = useCallback(() => {
    /* ℹ️ We need to wait until the DXGrid is updated to get the new columns */
    window.requestAnimationFrame(() => {
      const columnManagerInspectorPayload = {
        props: {
          ...components.viewComponents[clientViewUri || '']?.columnManagerInspector?.props,
          columns: getDxGridColumns(),
        },
      };
      dispatchActions.components.updateView('columnManagerInspector', clientViewUri, columnManagerInspectorPayload);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Method called when clicked on search icon on toolbar
   * Mutating original array @addBtn over here for the simplification
   */
  const onSearchClick = useCallback((addBtn: any[]) => {
    const index = addBtn.findIndex((btn: any) => btn.name === 'searchButton');
    addBtn.splice(index, 1);
    setShowSearchText(true);
  }, []);

  const actionsCollectionView: IAction[] = useMemo(() => {
    if (clientViewConfiguration) {
      return getActionsCollectionFromView({
        clientViewConfiguration,
        resolveAction,
        rowUri: components?.global?.orders?.rowData?._uri,
      });
    } else if (actionsCollection) {
      return actionsCollection;
    }
  }, [clientViewConfiguration, resolveAction, actionsCollection, components?.global?.orders?.rowData?._uri]);

  const getToolbarButtonsHandler = useMemo(
    () =>
      getToolbarButtons({
        add: [],
        addAfter: [],
        openColumnManagerClickHandler: showColumnManager,
        addBefore: [
          ...(actionsCollectionView
            ? [
                {
                  name: 'selectCartComponent',
                  location: 'after',
                  template: 'selectCart',
                  disabled: !actionCollectionActive,
                },
              ]
            : []),
        ],
        onSearchClick: onSearchClick,
        gridToolbarButtons,
        showSearchText,
      }),
    [actionsCollectionView, onSearchClick, showColumnManager, gridToolbarButtons, showSearchText],
  );

  function getSummary() {
    const summaryKeyProps: DxSummary[] = [
      {
        column: 'Instrument Behaviour',
        name: 'Instrument Behaviour',
        summaryType: 'custom',
      },
      {
        column: 'Identifier',
        name: 'Identifier',
        summaryType: 'custom',
      },
      {
        column: 'Market Map ID',
        name: 'Market Map ID',
        summaryType: 'custom',
      },
      {
        column: 'Asset Name',
        name: 'Asset Name',
        summaryType: 'custom',
      },
      {
        column: 'Primary Exchange',
        name: 'Primary Exchange',
        summaryType: 'custom',
      },
      {
        column: 'Asset Type',
        name: 'Asset Type',
        summaryType: 'custom',
      },
      {
        column: 'CCY',
        name: 'CCY',
        summaryType: 'custom',
      },
      {
        column: MarketMapColumns.MarketMapLastPrice,
        name: MarketMapColumns.MarketMapLastPrice,
        summaryType: 'custom',
      },
    ];

    const calculateCustomSummary = (options: any) => {
      const instrumentColumnDetails = options.component
        .state()
        .columns?.find((el: any) => el.dataField === 'Instrument');

      if (
        instrumentColumnDetails?.groupIndex > -1 &&
        options.summaryProcess === 'calculate' &&
        instrumentColumnDetails?.groupIndex === options.groupIndex
      ) {
        if (options.name === MarketMapColumns.MarketMapLastPrice && typeof options.value === 'string') {
          options.totalValue = options.value.split(' ')[0];
        } else {
          options.totalValue = options?.value;
        }
      }
    };

    if (clientViewConfiguration?.uri === 'instance/display_views/portfolio_positions')
      return getSummaryGlide(dataSource.schema, { summaryKeyProps }, calculateCustomSummary);

    return getSummaryGlide(dataSource.schema, {}, calculateCustomSummary);
  }

  const showColumnGroupedIcon = useCallback(
    (e: any) => {
      const visibleColumns = e.component.getVisibleColumns().filter((vc: any) => vc.hasOwnProperty('groupIndex'));
      if (!visibleColumns || !visibleColumns.length) {
        components?.viewComponents[clientViewUri]?.columnGrouped?.isGrouped &&
          dispatchActions.components.updateView('columnGrouped', clientViewUri, {
            isGrouped: false,
            isExpanded: false,
          });
        return;
      }
      if (!components?.viewComponents[clientViewUri]?.columnGrouped?.isGrouped) {
        dispatchActions.components.updateView('columnGrouped', clientViewUri, { isGrouped: true });
      }
    },
    [components?.viewComponents[clientViewUri]?.columnGrouped?.isGrouped],
  );
  const onContentReady = useCallback(
    (e: any) => {
      showColumnGroupedIcon(e);
    },
    [components?.viewComponents[clientViewUri]?.columnGrouped?.isGrouped],
  );

  const onOptionChanged = useCallback(
    (e: any) => {
      if (e.name === 'hoveredElement') return;
      if (e.fullName.includes('groupIndex')) {
        onColumnGrouped(e);
        showColumnGroupedIcon(e);
      }
      const layoutUri = e.component.state()?.uri;
      gridFilterChanges(e, components, clientViewUri, layoutUri);
    },
    [
      components?.viewComponents[clientViewUri]?.columnGrouped?.isGrouped,
      components?.viewComponents[clientViewUri]?.gridLayout?.selectedLayout?.uri,
      clientViewUri,
    ],
  );

  const onGroupedCallback = (isExpanded: boolean) => {
    dispatchActions.components.updateView('columnGrouped', clientViewUri, {
      isExpanded: !isExpanded,
    });
  };
  const onRowCollapsed = (e: any) => {
    const visibleRows = e.component.getVisibleRows().filter((vr: any) => vr.isExpanded);
    if ((!visibleRows || !visibleRows.length) && components?.viewComponents[clientViewUri]?.columnGrouped?.isExpanded) {
      dispatchActions.components.updateView('columnGrouped', clientViewUri, { isExpanded: false });
    }
  };
  const dxDataGridProps = useMemo(
    () => ({
      onCellClick: handleOnCellClick,
      toolbarButtons: getToolbarButtonsHandler,
      onOptionChanged,
      onContentReady,
      onGroupedCallback,
      onRowCollapsed,
      onRowPrepared: handleOnRowPrepared,
      // Disable context menu
      // onContextMenuPreparing: (contextEvent: any) => {
      //   addCloseMenuOption(contextEvent);

      //   if (contextEvent.column?.dataField === 'Order Date') {
      //     contextEvent.items[0].disabled = true;
      //   }
      //   contextEvent.event.preventDefault();
      //   dataGridRef.current.instance.selectRowsByIndexes([contextEvent.row?.rowIndex]);
      //   if (!onContextMenuOpen) return;
      //   onContextMenuOpen(contextEvent);
      // },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dataGridRef,
      getToolbarButtonsHandler,
      handleOnCellClick,
      components,
      onOptionChanged,
      onGroupedCallback,
      handleOnRowPrepared,
    ],
  );

  const customizeExcelCellHandler = useCallback(
    (data: any) => {
      glideExcelExport(data, dataSource);
    },
    [dataSource],
  );

  const dataGridProps: DxDataGridProps = {
    dataSource,
    dataSourceType: DataSourceType.GLIDE,
    customCellRenderKeyProps: {
      ...cellRenderers.selectionCellRenders(dataSource),
      ...objectCollectionColumns,
      ...customCellRenderKeyProps,
      ...cellRenderers.complianceCellRender(components.global.isDarkTheme),
      ...cellRenderers.currentResultCellRender,
      ...cellRenderers.complianceTestResultCellRender,
      ...cellRenderers.positionCellRender(components.global.isDarkTheme),
    },
    loadPanelEnabled: showLoadPanelEnabled,
    storageKey: storageKey,
    enableMultipleSelection,
    onSelectionChanged: handleOnSelectionChanged,
    tableKeyId: '_uri',
    gridTitle: headerText.title,
    gridDescription: headerText.description,
    id,
    indicatorSrc: showIndicatorSrc,
    useDarkTheme,
    enableLayouts,
    glideSummaryOptions,
    onRefresh,
    disableSelection: false,
    disableStateStoring: true,
    pageSizeOptions: [20, 50, 100, 200, 500],
    pageSize: 100,
    renderAsync: false,
    dxDataGridProps: dxDataGridProps,
    realTimeDataSource,
    repaintChangesOnly: true,
    highlightChanges: !!realTimeInfo,
    scrollingProps: scrollingProps ? scrollingProps : {},
    isFilterButtonLeftAligned: true,
    showSearch: showSearchText,
    searchVisibleColumnsOnly: true,
    toolbarIconsOrder: DEFAULT_TOOLBAR_ICONS_ORDER, // Default Icon order on toolbar can always be overridden for any screen specific
    showPaging: showPaging,
    allowHeaderSearch: true,
    autoExpandAll: components?.viewComponents[clientViewUri]?.columnGrouped?.isExpanded,
    exportProps: {
      customizeExcelCell: customizeExcelCellHandler,
      fileName: exportFileName || (dataSource?.viewUri?.lastSplitValue() as string)?.capitalizeWords('_') || id,
    },
    toolbarButtonsFromConfig: toolbarButtonsFromConfig,
    columnGrouped: components?.viewComponents[clientViewUri]?.columnGrouped,
  };

  const handleSettingsClick = () => {
    if (onSettingsClick) {
      onSettingsClick(dataGridRef);
    }
  };

  const Grid = useMemo(
    () => (
      <>
        <DxDataGrid ref={dataGridRef} {...dataGridProps}>
          {/*
          THE COMPONENT WILL NOT RENDER WHEN PASSED AS JSX!
          It appears only DevExtreme components are rendered correctly as JSX to DataGrid.
          This seems to be an issue with DevExtreme DataGrid
          */}
          {rowActions && rowActions.length > 0 && GridActionColumn({ actions: rowActions })}
          {actionsCollectionView && (
            <Template
              name="selectCart"
              render={() => <SelectTemplateRender actionsCollection={actionsCollectionView} />}
            />
          )}
          {enableLayouts && (
            <Template
              name="layouts"
              render={() => (
                <LayoutsTemplateRender
                  useDarkTheme={useDarkTheme}
                  onSettingsClick={handleSettingsClick}
                  dataGridRef={dataGridRef}
                  updateColumnManagerColumns={updateColumnManagerColumns}
                  clientViewUri={clientViewUri}
                />
              )}
            />
          )}
          {getGridTemplates && getGridTemplates({ useDarkTheme })}
          {getSummary()}
          {toolbarTemplates && toolbarTemplates.map((template: any) => template)}
        </DxDataGrid>
        {/* <GlideContextMenu onContextItemClick={onContextItemClick} /> */}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dataSource,
      useDarkTheme,
      showSearchText,
      actionsCollection,
      components?.global?.orders?.disabled,
      components?.viewComponents[clientViewUri]?.columnGrouped?.isGrouped,
      components?.viewComponents[clientViewUri]?.columnGrouped?.isExpanded,
      marketMapToggleStatus,
      showMMAuthenticationForm,
      marketMapToggleFlag,
      components?.viewComponents[clientViewUri]?.hypoScenario?.runStatus,
      components?.viewComponents[clientViewUri]?.hypoScenario?.runStatusUri,
      rowActions,
    ],
    // removed columnManagerInspector from dep array as it was causing performance hit
  );

  return (
    <>
      {GlideObjectManagerOverlay}
      {Grid}
    </>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  components: selectComponents(state),
});

const mapDispatchToProps = (dispatch: any): ReduxDispatch => ({
  resolveAction: (action: any, targetUri?: string) =>
    dispatch({ type: actions.action.DISPATCH_ACTION, payload: { action, targetUri } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DxGridClientView);
